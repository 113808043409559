import React, { useRef, useEffect } from "react";
import Img from "gatsby-image";
import "../style/font-style.css";
import { Box, Grid, Hidden } from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import WrapTypo from "../components/WrapTypo";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/footer";

const Useageflow = () => {
  const data = useStaticQuery(graphql`
    query {
      ringring: file(relativePath: { eq: "ringring.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      book: file(relativePath: { eq: "book.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      framework: file(relativePath: { eq: "framework.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      table: file(relativePath: { eq: "table.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      checkbox: file(relativePath: { eq: "checkbox.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      key: file(relativePath: { eq: "key.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bag: file(relativePath: { eq: "bag.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Header isBlack={true} />
      <Box maxWidth="900px" margin="auto">
        <Box width="90%" margin="auto" mt={5}>
          <Head />
          <Hidden xsDown>
            <Box marginLeft="auto" style={{ writingMode: "vertical-lr" }}>
              <WrapTypo fw="700" fs="18px" lh="250%" ls="10px">
                ご利用の流れ
              </WrapTypo>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box margin="auto" style={{ writingMode: "vertical-rl" }}>
              <WrapTypo fw="700" fs="18px" lh="250%" ls="10px">
                ご利用の流れ
              </WrapTypo>
            </Box>
          </Hidden>
          <Box mt={5}>
            <Grid container>
              <Grid item md={1} margin="auto">
                <Box textAlign="right" mt={2}>
                  <WrapTypo>step1.</WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <WrapTypo fs="200%" ls="2px">
                  お問い合わせ
                </WrapTypo>
                <Box mt={4} textAlign="justify">
                  <WrapTypo fs="16px" lh="180%" margin="auto">
                    家はお客様の一生に一度のお買い物。どんな些細なご質問に対しても真摯に向き合い、お答えさせて頂きます。土地をお探しの方には、ご希望の地域･予算をふまえて不動産の情報収集などもお手伝いいたします。また、資金計画や住宅ローンについては、しっかりと打合せを重ねながら「納得のいくお家づくり」をご希望の予算内でできるようお手伝いをさせて頂きます。
                  </WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" justify="center">
                <Box width="40%" margin="auto" mt={8}>
                  <Img fluid={data.ringring.childImageSharp.fluid} />
                </Box>

                {/* <Hidden mdUp>
                  <Box width="30%" margin="auto" mt={8}>
                    <Img fluid={data.ringring.childImageSharp.fluid} />
                  </Box>
                </Hidden> */}
              </Grid>
            </Grid>
          </Box>
          <Box mt={8}>
            <Grid container>
              <Grid item xs={1} md={1} margin="auto">
                <Box textAlign="right" mt={2}>
                  <WrapTypo>step2.</WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <WrapTypo fs="200%" ls="2px">
                  ゼロから設計図作成
                </WrapTypo>
                <Box mt={4} textAlign="justify">
                  <WrapTypo fs="16px" lh="180%" margin="auto">
                    弊社は一度使った設計図は二度と使いません。お客様へのヒアリングを元に構造材、造作材、床材、断熱材など、あらゆる素材や構造をゼロから設計し、納得の行くまで計画を揉みます。お客様の価値観、こだわり、大切にしていることは何かなど、どんな些細な想いも汲み取り、設計図に落とし込みます。また、資金についてのご相談にもしっかりと対応させていただき現実的な家づくりをご提案していきます。
                  </WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" justify="center">
                <Box width="40%" margin="auto" mt={8}>
                  <Img fluid={data.book.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={8}>
            <Grid container>
              <Grid item xs={1} md={1} margin="auto">
                <Box textAlign="right" mt={2}>
                  <WrapTypo>step3.</WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <WrapTypo fs="200%" ls="2px">
                  最終図面の決定、ご契約
                </WrapTypo>
                <Box mt={4} textAlign="justify">
                  <WrapTypo fs="16px" lh="180%" margin="auto">
                    納得できる設計が練りあがったら、最終的なお見積もりを作成させていただきます。その際、使用する素材やデザインなどを考慮しながら費用面の調整をし、ご希望予算内で最高のお家づくりができるよう、何度かお打ち合わせを行います。設計・費用ともにご納得いただけるものができましたら、ご契約となります。
                  </WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" justify="center">
                <Box width="40%" margin="auto" mt={8}>
                  <Img fluid={data.table.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={8}>
            <Grid container>
              <Grid item xs={1} md={1} margin="auto">
                <Box textAlign="right" mt={2}>
                  <WrapTypo>step4.</WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <WrapTypo fs="200%" ls="2px">
                  着工・現場打ち合わせ
                </WrapTypo>
                <Box mt={4} textAlign="justify">
                  <WrapTypo fs="16px" lh="180%" margin="auto">
                    着工式、地盤調査、地鎮祭などを経て、工事が始まります。
                    設計段階ではイメージできていなかった新しい気づきやギャップもあると思いますので、施工中も現場でお客様、現場監督・設計担当の立会いのもと、打ち合わせをさせて頂きます。必要な場合は施工途中でも微修正も行っていき、最後までお客様の想いを突き詰め、完成まで仕上げます。
                  </WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" justify="center">
                <Box width="40%" margin="auto" mt={8}>
                  <Img fluid={data.framework.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={8}>
            <Grid container>
              <Grid item xs={1} md={1} margin="auto">
                <Box textAlign="right" mt={2}>
                  <WrapTypo>step5.</WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <WrapTypo fs="200%" ls="2px">
                  竣工検査
                </WrapTypo>
                <Box mt={4} textAlign="justify">
                  <WrapTypo fs="16px" lh="180%" margin="auto">
                    お引渡し直前の大切な検査を念入りに行います。お客様立会いのもと新居の細部まで総点検を行います希望どおりの設計になっているか、今一度確認します。
                  </WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" justify="center">
                <Box width="40%" margin="auto" mt={8}>
                  <Img fluid={data.checkbox.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={8}>
            <Grid container>
              <Grid item xs={1} md={1} margin="auto">
                <Box textAlign="right" mt={2}>
                  <WrapTypo>step6.</WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <WrapTypo fs="200%" ls="2px">
                  お引き渡し
                </WrapTypo>
                <Box mt={4} textAlign="justify">
                  <WrapTypo fs="16px" lh="180%" margin="auto">
                    各種重要書類のご確認とともに、すべての鍵、
                    設備機器の説明書・保証書などが揃ったら、お引き渡しとなります。
                    問題がなければ書類にサインしていただきます。
                  </WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" justify="center">
                <Box width="40%" margin="auto" mt={8}>
                  <Img fluid={data.key.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={8} mb={8}>
            <Grid container>
              <Grid item xs={1} md={1} margin="auto">
                <Box textAlign="right" mt={2}>
                  <WrapTypo>step7.</WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <WrapTypo fs="200%" ls="2px">
                  アフターメンテナンス
                </WrapTypo>
                <Box mt={4} textAlign="justify">
                  <WrapTypo fs="16px" lh="180%" margin="auto">
                    HAYAFUJIはお家のお引き渡しが「終わり」ではなく「始まり」だと考えております。お引き渡し後も定期的に訪問点検を行い、不具合を早めに見つけることでお客様の安心の暮らしを見守り、いつでも気軽に相談できる頼れる存在を目指して、お付き合いをさせていただいております。
                  </WrapTypo>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} alignItems="center" justify="center">
                <Box width="40%" margin="auto" mt={8}>
                  <Img fluid={data.bag.childImageSharp.fluid} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Useageflow;
